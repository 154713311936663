// banners
export const getBannersPage = () => `/api/MainData/Banners/page`
export const createBanner = () => `/api/MainData/Banners/create`
export const updateBanner = () => `/api/MainData/Banners/update`
export const deleteBanner = () => `/api/MainData/Banners/delete`
// applicationgroups
export const getApplicationgroupPage = () => `/api/MainData/Applicationgroups/page`
export const getApplicationgroupAll = () => `/api/MainData/Applicationgroups/all`
export const createApplicationgroup = () => `/api/MainData/Applicationgroups/create`
export const updateApplicationgroup = () => `/api/MainData/Applicationgroups/update`
export const deleteApplicationgroup = () => `/api/MainData/Applicationgroups/delete`
//Applications
export const getApplicationPage = () => `/api/MainData/Applications/page`
export const getApplicationAll = () => `/api/MainData/Applications/all`
export const createApplication = () => `/api/MainData/Applications/create`
export const updateApplication = () => `/api/MainData/Applications/update`
export const deleteApplication = () => `/api/MainData/Applications/delete`

// RightGroups
export const getRightGroupPage = () => `/api/MainData/RightGroups/page`
export const getRightGroupAll = () => `/api/MainData/RightGroups/all`
export const createRightGroup = () => `/api/MainData/RightGroups/create`
export const updateRightGroup = () => `/api/MainData/RightGroups/update`
export const deleteRightGroup = () => `/api/MainData/RightGroups/delete`
//Rights
export const getRightPage = () => `/api/MainData/Rights/page`
export const getRightAll = (groupId) => `/api/MainData/Rights/all?groupId=${groupId}`
export const createRight = () => `/api/MainData/Rights/create`
export const updateRight = () => `/api/MainData/Rights/update`
export const deleteRight = () => `/api/MainData/Rights/delete`
//Customers
export const getCustomerAll = () => `/api/MainData/Customers/all`
export const getCustomer = (id) => `/api/MainData/Customers/get?id=${id}`
export const getCustomerTree = (id) => `/api/MainData/Customers/tree?id=${id}`
export const getCustomerPage = () => `/api/MainData/Customers/page`
export const createCustomer = () => `/api/MainData/Customers/create`
export const updateCustomer = () => `/api/MainData/Customers/update`
export const deleteCustomer = () => `/api/MainData/Customers/delete`
export const getCustomerApplications = (id) => `/api/MainData/Customers/getApplications?id=${id}`
export const setCustomerApplication = () => `/api/MainData/Customers/setApplication`
export const getCustomerRights = (id) => `/api/MainData/Customers/getRights`
export const addCustomerRight = (id) => `/api/MainData/Customers/addRight`
export const updateCustomerRight = (id) => `/api/MainData/Customers/updateRight`
export const deleteCustomerRight = (id) => `/api/MainData/Customers/deleteRight`
export const getAllRights = (id) => `/api/MainData/Customers/allRights`

//Distributor
export const getDistributor = (id) => `/api/MainData/Distributors/get?id=${id}`
export const getDistributorPage = () => `/api/MainData/Distributors/page`
export const createDistributor = () => `/api/MainData/Distributors/create`
export const updateDistributor = () => `/api/MainData/Distributors/update`
export const deleteDistributor = () => `/api/MainData/Distributors/delete`
export const importDistributor = () => `/api/MainData/Distributors/import`

//DistributorEngineer
export const getDistributorEngineer = (id) => `/api/MainData/DistributorEngineers/get?id=${id}`
export const getDistributorEngineerPage = () => `/api/MainData/DistributorEngineers/page`
export const createDistributorEngineer = () => `/api/MainData/DistributorEngineers/create`
export const updateDistributorEngineer = () => `/api/MainData/DistributorEngineers/update`
export const deleteDistributorEngineer = () => `/api/MainData/DistributorEngineers/delete`


//用户
export const getCustomerUserPage = (cid) => `/api/MainData/CustomerUsers/page?customerId=${cid}`
export const createCustomerUser = (cid) => `/api/MainData/CustomerUsers/create?customerId=${cid}`
export const updateCustomerUser = (cid) => `/api/MainData/CustomerUsers/update?customerId=${cid}`
export const deleteCustomerUser = (cid) => `/api/MainData/CustomerUsers/delete?customerId=${cid}`
export const getCustomerUserRole = (cid) => `/api/MainData/CustomerUsers/role?customerId=${cid}`
export const lockCustomerUser = (cid) => `/api/MainData/CustomerUsers/lock?customerId=${cid}`

//Stations
export const createStation = () => `/api/MainData/Stations/create`
export const updateStation = () => `/api/MainData/Stations/update`
export const deleteStation = () => `/api/MainData/Stations/delete`
export const getStation = (stationId) => `/api/MainData/Stations/get?id=${stationId}`
export const getDevice = (devieId) => `/api/MainData/Stations/getDevice?id=${devieId}`
export const getStationDevices = () => `/api/MainData/Stations/getDevices`
export const updateStationSldConfigs = () => `/api/MainData/Stations/updateSldConfigs`

export const createDevice = () => `/api/MainData/Stations/createDevice`
export const updateDevice = () => `/api/MainData/Stations/updateDevice`
export const deleteDevice = () => `/api/MainData/Stations/deleteDevice`
export const copyDevice = () => `/api/MainData/Stations/copyDevice`
export const getDeviceDatas = (deviceId) => `/api/MainData/Stations/getDatas?deviceId=${deviceId}`
export const updateDeviceData = (deviceId) => `/api/MainData/Stations/updateDatas?deviceId=${deviceId}`
export const getStationTree = (customerId) => `/api/MainData/Stations/tree?id=${customerId}`
export const addDocuments = (deviceId) => `/api/MainData/Stations/addDocuments?deviceId=${deviceId}`
export const getDocuments = (deviceId) => `/api/MainData/Stations/getDocuments?deviceId=${deviceId}`
export const getDevicePath = (deviceId) => `/api/MainData/Stations/getDevicePath?deviceId=${deviceId}`
export const getDeviceMaterials=()=>`/api/MainData/Stations/get-materials`
export const addDeviceMaterials=()=>`/api/MainData/Stations/add-materials`
export const removeDeviceMaterials=()=>`/api/MainData/Stations/remove-materials`
export const setDeviceRemommandMaterials=()=>`/api/MainData/Stations/set-recommand-material`
export const getStationPath = (stationId) => `/api/MainData/Stations/getStationPath?stationId=${stationId}`
export const uploadDrawing = () => `/api/MainData/Stations/uploadDrawing`
//Factories
export const getFactoryPage = () => `/api/MainData/Factories/page`
export const getFactoryAll = () => `/api/MainData/Factories/all`
export const createFactory = () => `/api/MainData/Factories/create`
export const updateFactory = () => `/api/MainData/Factories/update`
export const deleteFactory = () => `/api/MainData/Factories/delete`
//Projects
export const getProjectPage = () => `/api/MainData/Projects/page`
export const getProject = (id) => `/api/MainData/Projects/get?id=${id}`
export const createProject = () => `/api/MainData/Projects/create`
export const updateProject = () => `/api/MainData/Projects/update`
export const deleteProject = () => `/api/MainData/Projects/delete`
export const getTeamMembers = () => `/api/MainData/Projects/getTeamMembers`
export const removeTeamMember = () => `/api/MainData/Projects/removeTeamMember`
export const setTeamMember = () => `/api/MainData/Projects/setTeamMember`
export const getContacts = () => `/api/MainData/Projects/getContacts`
export const removeContact = () => `/api/MainData/Projects/removeContact`
export const createContact = () => `/api/MainData/Projects/createContact`
export const updateContact = () => `/api/MainData/Projects/updateContact`
export const getDevices = () => `/api/MainData/Projects/getDevices`
export const getStations = (projectId) => `/api/MainData/Projects/getStations?projectId=${projectId}`
export const addStation = () => `/api/MainData/Projects/addStation`
export const addDevice = () => `/api/MainData/Projects/addDevice`
export const removeStation = () => `/api/MainData/Projects/removeStation`
export const removeDevice = () => `/api/MainData/Projects/removeDevice`
export const setProjectProperty = () => `/api/MainData/Projects/setProperty`

//DeviceTypes
export const getDeviceTypes = () => `/api/MainData/DeviceTypes/tree`
export const getDeviceType = (id) => `/api/MainData/DeviceTypes/get?id=${id}`

export const createDeviceType = () => `/api/MainData/DeviceTypes/create`
export const updateDeviceType = () => `/api/MainData/DeviceTypes/update`
export const deleteDeviceType = () => `/api/MainData/DeviceTypes/delete`
export const getDeviceMetaDatas = () => `/api/MainData/DeviceTypes/getMetaDatas`
export const createMetaData = () => `/api/MainData/DeviceTypes/createMetaData`
export const updateMetaData = () => `/api/MainData/DeviceTypes/updateMetaData`
export const deleteMetaData = () => `/api/MainData/DeviceTypes/deleteMetaData`
export const getDeviceTypeMetaDatas = (typeId) => `/api/MainData/DeviceTypes/getTypeMetaDatas?typeId=${typeId}`

//MaterialCatalogs
export const getMaterialCatalogs = () => `/api/MainData/MaterialCatalogs/tree`
export const getMaterialCatalog = (id) => `/api/MainData/MaterialCatalogs/get?id=${id}`

export const createMaterialCatalog = () => `/api/MainData/MaterialCatalogs/create`
export const updateMaterialCatalog = () => `/api/MainData/MaterialCatalogs/update`
export const deleteMaterialCatalog = () => `/api/MainData/MaterialCatalogs/delete`
export const getMaterialMetaDatas = () => `/api/MainData/MaterialCatalogs/getMetaDatas`
export const createMaterialMetaData = () => `/api/MainData/MaterialCatalogs/createMetaData`
export const updateMaterialMetaData = () => `/api/MainData/MaterialCatalogs/updateMetaData`
export const deleteMaterialMetaData = () => `/api/MainData/MaterialCatalogs/deleteMetaData`
export const getMaterialCatalogMetaDatas = (catalogId) => `/api/MainData/MaterialCatalogs/getCatalogMetaDatas?catalogId=${catalogId}`

//Materials
export const getMaterials = () => `/api/MainData/Materials/page`
export const getMaterial = (id) => `/api/MainData/Materials/get/?id=${id}`
export const createMaterial = () => `/api/MainData/Materials/create`
export const updateMaterial = () => `/api/MainData/Materials/update`
export const deleteMaterial = () => `/api/MainData/Materials/delete`
export const getMaterialDatas = (materialId) => `/api/MainData/Materials/getDatas?materialId=${materialId}`
export const updateMaterialData = (materialId) => `/api/MainData/Materials/updateDatas?materialId=${materialId}`
export const getDependentMaterials = () => `/api/MainData/Materials/getDependentMaterials`
export const getUndependentMaterials = () => `/api/MainData/Materials/getUndependentMaterials`
export const addMasters = () => `/api/MainData/Materials/addMasters`
export const removeMasters = () => `/api/MainData/Materials/removeMasters`
export const getAssociatedDeviceTypes = () => `/api/MainData/Materials/getAssociatedDeviceTypes`
export const getUnAssociatedDeviceTypes = (materialId) => `/api/MainData/Materials/getUnAssociatedDeviceTypes?materialId=${materialId}`
export const addAssociatedDeviceType = () => `/api/MainData/Materials/addAssociatedDeviceType`
export const removeAssociatedDeviceType = () => `/api/MainData/Materials/removeAssociatedDeviceType`
export const setRecommandMaterial = () => `/api/MainData/Materials/setRecommandMaterial`
export const importMaterials = () => `/api/MainData/Materials/import`

//Instruments
export const getInstrumentPage = () => `/api/MainData/Instruments/page`
export const createInstrument = () => `/api/MainData/Instruments/create`
export const updateInstrument = () => `/api/MainData/Instruments/update`
export const deleteInstrument = () => `/api/MainData/Instruments/delete`
export const importInstrument = () => `/api/MainData/Instruments/import`

//Files

export const uploadFile = () => `api/Files/create`
export const createDirectory = () => `api/Files/createDirectory`
export const getFileInfo = () => `api/Files/get`
export const getFilesInfo = () => `api/Files/gets`
export const getPath = () => `api/Files/path`
export const getFileSystem = () => `api/Files/file-system`
export const searchFile = () => `api/Files/search`
export const downloadFile = () => `api/Files/download`
export const deleteFiles = () => `api/Files/delete`
export const deleteDirectory = () => `api/Files/delete-path`
export const addFileCover = () => `api/Files/cover`

export const viewFile = () => `api/Files/view`

//Sap rfc

export const sapZbapiGetBomDmx02 = () => `api/MainData/SapRfc/ZBAPI-GET-BOM-DMX-02`
export const testSapZbapiGetBomDmx02 = () => `api/MainData/SapRfc/TEST-ZBAPI-GET-BOM-DMX-02`

export const getImportanceWeightsPage=()=>`/api/MainData/ImportanceWeights/page`
export const deleteImportanceWeights=()=>`/api/MainData/ImportanceWeights/delete`
export const createImportanceWeights=()=>`/api/MainData/ImportanceWeights/create`
export const updateImportanceWeights=()=>`/api/MainData/ImportanceWeights/update`

export const DeviceImportanceWeightCatalogs=()=>`/api/MainData/ImportanceWeightCatalogs/device`
export const getImportanceWeightCatalogsPage=()=>`/api/MainData/ImportanceWeightCatalogs/page`
export const deleteImportanceWeightCatalogs=()=>`/api/MainData/ImportanceWeightCatalogs/delete`
export const createImportanceWeightCatalogs=()=>`/api/MainData/ImportanceWeightCatalogs/create`
export const updateImportanceWeightCatalogs=()=>`/api/MainData/ImportanceWeightCatalogs/update`
